<template>
<!-- 绑定手机号 -->
	<div class="mobilePhone">
		<div class="main">
			<div class="bangding">绑定手机号</div>
			<div class="text">
				<div class="dan">
					<div class="title">手机号：</div>
					<el-input v-model="phone" placeholder="请输入手机号码"></el-input>
				</div>
				<div class="dan">
					<div class="title">验证码：</div>
					<el-input class="code" v-model="code" placeholder="请输入验证码"></el-input>
					<el-button type="success" plain @click="ontoCode()" v-if="time == 60">发送验证码</el-button>
					<el-button type="success" plain v-else disabled> {{time}}秒后可发送</el-button>
				</div>
				<div class="dan button">
					<el-button type="danger" round @click="onmobilePhone">提交绑定</el-button>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data () {
		return {
			time:60,
			setinTime:null,
			phone:'',//手机号
			code:'',//验证码
		}
	},

	mounted(){
		console.log(this.$route.query.token)
	},

	methods: {
		ontimeCode(){
			this.time--
			this.setinTime = setInterval(() => {
				this.time--
				if(this.time < 1){
					clearInterval(this.setinTime)
					this.time = 60
				}
			}, 1000);
		},
		//发送验证码
		ontoCode(){
			if(this.phone == ''){this.$message({message: '请输入手机号码',type: 'warning'}); return}
			var param = {
				phone:this.phone,
			}
			this.$service.get(this.$api.get_phone_code,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '验证码已发送',type: 'success'});
					this.ontimeCode()
				}
			})
		},
		//绑定手机号
		onmobilePhone(){
			var token = this.$route.query.token
			console.log(this.$route.query)
			if(this.phone == ''){this.$message({message: '请输入手机号码',type: 'warning'}); return}
			if(this.code == ''){this.$message({message: '请输入验证码',type: 'warning'}); return}
			var param = {
				phone:this.phone,
				code:this.code,
			}
			token ? param.token = this.$route.query.token : ''
			this.$service.post(this.$api.post_bing_phone,param, (res)=> {
				if(res.code == '200'){
					this.$message({
						message: '手机号绑定成功',
						type: 'success'
					});
					window.location = window.location.origin + '/?token='+token
				}
			})
		},
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	}
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 750px) {
	.mobilePhone{
		height: calc(100vh - 200px);
		display: flex;
		align-items: center;
		justify-content: center;
		.main{
			text-align: center;
			.bangding{
				font-size: 38px;
				margin-bottom: 20px;
				font-weight: 500;
			}
		}
		.dan{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			&.button{
				justify-content: center;
			}
		}
		.text{
			width: 380px;
			.el-input{
				width: 300px;
				margin-right: 10px;
			}
			.code{
				width: 196px;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.mobilePhone{
		height: calc(100vh - 5rem);
		display: flex;
		align-items: center;
		justify-content: center;
		.main{
			text-align: center;
			.bangding{
				font-size: 38px;
				margin-bottom: 20px;
				font-weight: 500;
			}
		}
		.text{
			// width: 500px;
			text-align: center;
			.dan{
				align-items: center;
				display: flex;
				.code{
					width: 3rem;
					margin-right: .3rem;
				}
				margin-bottom: .3rem;
				&.button{
					justify-content: center;
				}
			}
			.el-input{
				width: 300px;
			}
		}
	}
}

</style>
